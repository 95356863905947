import React from 'react';
import * as bp4 from '@blueprintjs/core';
import * as bp4d from '@blueprintjs/datetime2';
import { Formik } from 'formik';
import moment from 'moment';
import axios from 'axios';
import { Radio } from '@blueprintjs/core';

export default function AddHedgeDialog({ isOpen = false, close = () => { }, country, energy, data }) {
    const cc = country.length > 2 ? country.substring(0, 2) : country;
    const unit = energy.id !== 'co2' ? 'MWh' : 'co2 cert';
    const currentYear = (new Date()).getFullYear();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const initialValues = {
        country: cc,
        energy: energy.id,
        year: 0,
        value: 0,
        volume: 0,
        price: 0.0,
        fee: 0.0,
        period: 'Y',
        periodValue: currentYear % 2000,
        date: moment().format('YYYY-MM-DD'),
        label: '',
        type: 'hedge'
    };
    const validate = (values) => {
        const errors = {};
        return errors;
    };
    return <bp4.Dialog isOpen={isOpen} onClose={close} title={<span>Add Hedge for <span className={`flag-icon flag-icon-${cc} flag-icon-squared flag-span`}></span></span>} icon="plus" isCloseButtonShown={true}>
        <div className={bp4.Classes.DIALOG_BODY}>
            <Formik initialValues={initialValues} validate={validate} onSubmit={async (values, { setErrors, resetForm }) => {
                const {energy, year, label, volume, price, fee, period, periodValue, date, type} = values;
                var periodString = '';
                const actualYear = currentYear + year;
                switch(period) {
                    case 'Y':
                        periodString = `Y-${actualYear}`;
                        break;
                    case 'Q':
                        periodString = `Q${periodValue}-${actualYear}`;
                        break;
                    case 'M':
                        periodString = `${months[periodValue - 1]}.${actualYear}`;
                        break;
                }
                const realDate = moment(date, 'YYYY-MM-DD').toDate();
                const body = {
                    country,
                    energy,
                    year,
                    label: type === 'hedge'? label : (type === 'total'? 'Total' : 'Spot'),
                    volume,
                    price,
                    fee,
                    period: type === 'hedge'? periodString : '',
                    date: 25569.0 + ((realDate.getTime() - (realDate.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24)) +1//Math.round((moment(date, 'YYYY-MM-DD').toDate().getTime() + 25569)/(86400*1000))
                };
                await axios.post(`${process.env.REACT_APP_HOST || 'https://commodity-fore.com'}/api/v2/hedge`, body);
                resetForm();
                close();
            }}>
                {({
                    values,
                    setValues,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    resetForm
                }) => {
                    const calcualteValue = (value) => {
                        if(value === 0)
                            return 0;
                        if(!data || !data.barCharts)
                            return 1;
                        const base = (data.barCharts.data.filter(({year}) => year === values.year).map(({volume}) => volume).reduce((a, b) => a + b, 0)) + value;
                        if(base === 0)
                            return 1;
                        return value/base*100;
                    };
                    return (
                        <form onSubmit={handleSubmit}>
                            <bp4.FormGroup
                                label="Year"
                                labelInfo="(required)"
                                helperText="The year this hedge is for.">
                                <bp4.Slider
                                    fill
                                    required
                                    min={0}
                                    max={5}
                                    stepSize={1}
                                    labelRenderer={(years) => `${currentYear + years}`}
                                    disabled={isSubmitting}
                                    value={values.year}
                                    onChange={year => setFieldValue('year', year)} />
                            </bp4.FormGroup>
                            <bp4.FormGroup label="Type" helperText="Select the type ">
                                <bp4.RadioGroup 
                                    onChange={e => setFieldValue('type', e.currentTarget.value)} 
                                    selectedValue={values.type || 'hedge'}
                                    inline={true}>
                                    <Radio value="hedge" label="Hedge" />
                                    <Radio value="spot" label="Spot" />
                                    <Radio value="total" label="Total" />
                                </bp4.RadioGroup>
                            </bp4.FormGroup>
                            <bp4.FormGroup label="Label" labelInfo="(required)" helperText="Label of the hedging position (use 'Spot' or 'offeneMenge' for special use).">
                                <bp4.InputGroup fill required disabled={isSubmitting || values.type !== 'hedge'} value={values.type === 'hedge'? values.label : (values.type === 'total'? 'Total' : 'Spot')} onChange={({target: {value}}) => setFieldValue('label', value)} />
                            </bp4.FormGroup>
                            <bp4.FormGroup
                                label="Volume"
                                labelInfo="(required)"
                                helperText={`The total volume of ${energy.name} in ${unit}.`}>
                                <bp4.NumericInput
                                    fill
                                    required
                                    min={-1000000}
                                    max={1000000}
                                    stepSize={1}
                                    leftElement={<bp4.Tag intent={calcualteValue(values.volume) > 0? 'success' : 'warning'}>{calcualteValue(values.volume).toFixed(2)}%</bp4.Tag>}
                                    rightElement={<bp4.Tag>{unit}</bp4.Tag>}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting}
                                    value={values.volume}
                                    onValueChange={volume => setFieldValue('volume', volume)} />
                            </bp4.FormGroup>
                            <bp4.FormGroup
                                label="Price"
                                labelInfo="(required)"
                                helperText={`The price per ${unit} int Euro (€).`}>
                                <bp4.NumericInput
                                    name="volume"
                                    fill
                                    required
                                    min={0}
                                    max={1000000}
                                    stepSize={0.1}
                                    minorStepSize={0.01}
                                    rightElement={<bp4.Tag>{`€/${unit}`}</bp4.Tag>}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting || (values.type !== 'hedge' && values.type !== 'spot')}
                                    value={values.price}
                                    onValueChange={price => setFieldValue('price', price)} />
                            </bp4.FormGroup>
                            <bp4.FormGroup
                                labelInfo="(required)">
                                <bp4.NumericInput
                                    name="fee"
                                    fill
                                    required
                                    min={0}
                                    max={1000000}
                                    stepSize={0.1}
                                    minorStepSize={0.01}
                                    rightElement={<bp4.Tag>{`€`}</bp4.Tag>}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting || values.type !== 'hedge'}
                                    value={values.fee}
                                    onValueChange={fee => setFieldValue('fee', fee)} />
                            </bp4.FormGroup>
                            <bp4.FormGroup label="Period" labelInfo="(required)" helperText="The month, quarter, year this hedge is applied for.">
                                <bp4.RadioGroup inline onBlur={handleBlur} disabled={isSubmitting || values.type !== 'hedge'} selectedValue={values.period} onChange={e => {
                                    const period = e.currentTarget.value;
                                    setFieldValue('period', period);
                                    console.log(`Selected period: ${period}`);
                                    switch (period) {
                                        case 'Y':
                                            setFieldValue('periodValue', (currentYear + values.year) % 2000);
                                            break;
                                        case 'Q':
                                        case 'M':
                                            setFieldValue('periodValue', 1);
                                            break;
                                    }
                                }}>
                                    <bp4.Radio label={`Y-${currentYear + values.year}`} value="Y" />
                                    <bp4.Radio label="Quater" value="Q" />
                                    <bp4.Radio label="Month" value="M" />
                                </bp4.RadioGroup>
                                {values.period === 'Q' && <bp4.Slider required min={1} max={4} labelRenderer={(quater) => `Q-${quater}-${currentYear + values.year}`} onBlur={handleBlur} value={values.periodValue} onChange={quater => setFieldValue('periodValue', quater)} />}
                                {values.period === 'M' && <bp4.Slider required min={1} max={12} labelRenderer={(month) => `${months[month - 1]}.${(currentYear + values.year) % 2000}`} onBlur={handleBlur} disabled={isSubmitting} value={values.periodValue} onChange={month => setFieldValue('periodValue', month)} />}
                            </bp4.FormGroup>
                            <bp4.FormGroup label="Date" labelInfo="(required)" helperText="Date of acquisition.">
                                <bp4d.DateInput2
                                    value={values.date}
                                    onChange={date => setFieldValue('date', date)}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting || values.type !== 'hedge'}
                                    min={moment('1.1.2000', 'DD.MM.YYYY').toDate()}
                                    max={moment('31.12.2999', 'DD.MM.YYYY').toDate()}
                                    formatDate={date => date && moment(date).format('DD.MM.YYYY')}
                                    parseDate={str => str && moment(str, 'DD.MM.YYYY').toDate()}
                                    popoverProps={{ usePortal: false }} />
                            </bp4.FormGroup>
                            <div className={bp4.Classes.DIALOG_FOOTER_ACTIONS}>
                                <bp4.Button intent='primary' icon='saved' type="submit">Save</bp4.Button>
                                <bp4.Button intent='danger' icon='cross-circle' onClick={() => {
                                    resetForm();
                                    close();
                                    }}>Cancel</bp4.Button>
                                <bp4.Button intent='warning' icon='lab-test' onClick={() => {
                                    setValues({
                                        country: cc,
                                        energy: energy.id,
                                        year: 1,
                                        volume: 20000,
                                        price: 50.45,
                                        fee: 10.0,
                                        period: 'Y',
                                        periodValue: 1,
                                        date: moment().format('YYYY-MM-DD')
                                    });
                                }}>Test Data</bp4.Button>
                            </div>
                        </form>);
                }}
            </Formik>
        </div>
    </bp4.Dialog>;
}