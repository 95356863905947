import React from 'react';
import {Redirect} from 'react-router-dom';

// Stylesheets
import '../stylesheets/LoginMask.css';

// React Context
import CommodityForeContext from '../CommodityForeContext.js';

import RestApiClient from '../logic/RestApiClient';

const apiClient = new RestApiClient();

export default class LoginMask extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            error: false
        };
        this.setUsername = this.setUsername.bind(this);
        this.setPassword = this.setPassword.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.login = this.login.bind(this);
    }

    render() {
        const {authenticated} = this.context;
        if(authenticated) {
            return (<Redirect to="/" />);
        }
        return (
            <div id="Content" className="LoginMask">
                {this.state.error &&
                    <p id="LoginMaskError"><strong>Error! </strong> Either your username or password is wrong.</p>}
                <label id="UsernameLabel" htmlFor="user-name">Username:</label>
                <input id="UsernameInput" name="user-name" type="text" placeholder="Your assigned username." onChange={this.setUsername} onKeyPress={this.handleKeyPress} />
                <label id="PasswordLabel" htmlFor="password">Password:</label>
                <input id="PasswordInput" name="password" type="password" placeholder="Password" onChange={this.setPassword} onKeyPress={this.handleKeyPress} />
                <button id="LoginButton" onClick={this.login}>Login</button>
            </div>
        );
    }

    setUsername(e) {
        const username = e.target.value;
        this.setState({
            ...this.state,
            username
        });
    }

    setPassword(e) {
        const password = e.target.value;
        this.setState({
            ...this.state,
            password
        });
    }

    async handleKeyPress(event) {
        if(event.key === 'Enter') {
            await this.login();
        }
    }

    async login() {
        const {username,password} = this.state;
        try {
            const {access_token} = await apiClient.login(username, password);
            apiClient.setToken(access_token);
            this.props.onLogin(username, access_token);
            // Reset State
            this.setState({
                username: "",
                password: "",
                error: false
            });
        } catch(e) {
            console.log(e);
            this.setState({
                ...this.state,
                error: true
            });
        }
    }
}

LoginMask.contextType = CommodityForeContext;