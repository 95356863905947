import React from 'react';

export default class IndicatorArrow extends React.Component {

    render() {
        const {rotation} = this.props;
        const color = this.getArrowColor(rotation);
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="39.998" viewBox="0 0 40 39.998" className={this.props.className}>
                <path style={{fill: color, transform:  `rotate(${-(rotation*90) - 90}deg)`, transformOrigin: '50% 50%'}}
                      d="M20,40A20.005,20.005,0,0,1,12.215,1.572,20,20,0,0,1,34.14,34.142,19.936,19.936,0,0,1,20,40Zm-8.51-18.052,8.674,8.675,8.674-8.675H22.333V11.1a2.053,2.053,0,0,0-2.169-2.168A2.053,2.053,0,0,0,17.995,11.1V21.948H11.489Z" 
                       />
            </svg>);
    }

    getArrowColor(value) {
        if(value < -0.2) return '#046a38'; // Green
        else if (value > 0.2) return '#FFFF00'; // Yellow
        return '#75787b'; // Gray
    }

}