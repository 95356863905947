import React from 'react';

// Custom Components
import CommodityIndicatorChart from './CommodityIndicatorChart';

// Stylesheets
import '../stylesheets/CommodityIndicators.css';

// Utilities
import RestApiClient from '../logic/RestApiClient';

const apiClient = new RestApiClient();

// Globals
const indicators = [
    {
        id: '2-months',
        label: '2 Months',
        displayUnit: 'month'
    },
    {
        id: '4-months',
        label: '4-6 Months',
        displayUnit: 'quarter'
    },
    {
        id: '1-year',
        label: '1 Year',
        displayUnit: 'quarter'
    },
    {
        id: '1-to-3-years',
        label: '1 to 3 Years',
        displayUnit: 'quarter'
    }
];

export default class CommodityIndicators extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            arrows: []
        };
    }

    render() {
        return (
            <div id="CommodityIndicators">
                {indicators.map((indicator, index) => (
                    <CommodityIndicatorChart key={indicator.id} energy={this.props.energy} indicator={indicator} arrowIndicator={this.state.arrows[index]} />
                ))}
            </div>
        );
    }

    componentWillMount = async () => {
        const {arrows} = await apiClient.loadOverview(this.props.energy.id);
        this.setState({...this.state,arrows});
    };

}