import React from 'react';
import Chart from 'chart.js';
import { Link } from 'react-router-dom';

// Custom Components
import IndicatorArrow from '../utils/IndicatorArrow';

// Helpers
import RestApiClient from '../logic/RestApiClient';

// Globals
const apiClient = new RestApiClient();

const statusHedgeChartConfig = {
    tooltips: {
        callbacks: {
            label: function(tooltipItem, data) {
                return `Value ${(tooltipItem.value*100).toFixed(2)}%`;
            }
        }
    },
    scales: {
        xAxes: [{
            barPercentage: 0.5,
            id: "x-axis-0",
            stacked: true,
        }],
        yAxes: [{
            stacked: true,
            ticks: {
                max: 1,
                min: 0,
                stepSize: 0.25,
                callback: (value) => {
                    return `${(parseFloat(value) * 100).toFixed(0)}%`;
                }
            },
        }]
    },
    legend: {
        display: false
    }
};

export default class CommodityOverview extends React.Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.state = {
            spot: 0,
            alertType: 'Hold',
            statusHedgeData: {},
            arrowIndicators: [],
            valueIndicators: []
        };
    }

    render() {
      const {energy} = this.props;
      const spotMap = {
        'co2': 'EEX-EUA Spot',
        'gas': 'Pegas Spot (AT)',
        'power': 'EPEX Spot (DE)'
    };
      return (
          <div className="CommodityOverview">
              <div className="OverviewHeader">
                  <div className="OverviewTitle">
                      <h4>OVERVIEW</h4>
                      <Link to={`/indicators?energyId=${energy.id}`}>{energy.name}</Link>
                  </div>
                  <div className="ActualPrice">
                      <h4>{spotMap[energy.id]}</h4>
                      <h3>{energy.id !== 'co2'? '€/MWh' : '€'} {this.state.spot.toFixed(2)}</h3>
                      <div>
                          <img src="images/barchart.png" alt="" />
                      </div>
                  </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' }}>
                <table style={{ width: '100%', textAlign: 'center', fontSize: '12pt', borderCollapse: 'collapse' }}>
                    <thead>
                    <tr>
                        <td style={{ fontWeight: 'bold', padding: '10px', borderBottom: '1px solid black' }}></td>
                        <td style={{ fontWeight: 'bold', padding: '10px', borderBottom: '1px solid black' }}>Current Y</td>
                        <td style={{ fontWeight: 'bold', padding: '10px', borderBottom: '1px solid black' }}>Future Ys</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{ fontWeight: 'bold', padding: '10px', borderRight: '1px solid black' }}>Upper</td>
                        <td className={`${this.state.alertType}AlertBox`} style={{ padding: '10px' }} title={this.getAlertText(this.state.alertType, this.state.alertActionValue)}>{(this.state.futureAlerts && this.state.futureAlerts.upper && this.state.futureAlerts.lower) ? this.state.alertType : '-'}!</td>
                        <td className={`${this.state.futureAlerts?.upper?.label}AlertBox`} style={{ padding: '10px' }}>{(this.state.futureAlerts && this.state.futureAlerts.upper && this.state.futureAlerts.lower) ? this.state.futureAlerts.upper.label : '-'}!</td>
                    </tr>
                    <tr>
                        <td style={{ fontWeight: 'bold', padding: '10px', borderRight: '1px solid black' }}>Lower</td>
                        <td className={`${this.state.alertMonthly?.label}AlertBox`} style={{ padding: '10px' }} title={this.state.alertMonthly && this.state.alertMonthly.label && this.getAlertText(this.state.alertMonthly.label, this.state.alertMonthly.alertActionValue)}>{(this.state.alertMonthly && this.state.alertMonthly.label !== '-') ? this.state.alertMonthly.label : '-'}!</td>
                        <td className={`${this.state.futureAlerts?.lower?.label}AlertBox`} style={{ padding: '10px' }}>{(this.state.futureAlerts?.lower && this.state.futureAlerts?.lower?.label !== '-') ? this.state.futureAlerts.lower.label : '-'}</td>
                    </tr>
                    </tbody>
                </table>
              </div>
              {/*<div className="AlertContainer">
                {(this.state.futureAlerts && this.state.futureAlerts.upper && this.state.futureAlerts.lower) && <h2>Current years:</h2>}
                  <br />
                  <h3>Upper threshold:</h3>
                  <div className='AlertThresholdContainer'>
                    <div className={`${this.state.alertType}AlertBox`}>
                        <p>
                            <b>{this.state.alertType}! </b>
                            {this.getAlertText(this.state.alertType, this.state.alertActionValue)}
                        </p>
                    </div>
                  </div>
                  {(this.state.alertMonthly && this.state.alertMonthly.label !== '-') &&
                    (<div>
                        <h3>Lower threshold:</h3>
                        <div className={`${this.state.alertMonthly.label}AlertBox`}>
                            <p>
                                <b>{this.state.alertMonthly.label}! </b>
                            {this.getAlertText(this.state.alertMonthly.label, this.state.alertMonthly.alertActionValue)}
                            </p>
                        </div>
                     </div>)}
                {(this.state.futureAlerts && this.state.futureAlerts.upper && this.state.futureAlerts.lower) && <div>
                <br />
                <hr />
                <h2>Future years:</h2>
                <br />
                <div className='AlertThresholdContainer'>
                    <h3>Upper threshold:</h3>
                    <div className={`${this.state.futureAlerts.upper.label}AlertBox`}>
                        <p>
                            <b>{this.state.futureAlerts.upper.label}! </b>
                            {this.getAlertText(this.state.futureAlerts.upper.label, this.state.futureAlerts.upper.value)}
                        </p>
                    </div>
                  </div>
                  {(this.state.futureAlerts.lower && this.state.futureAlerts.lower.label !== '-') &&
                    (<div>
                        <h3>Lower threshold:</h3>
                        <div className={`${this.state.futureAlerts.lower.label}AlertBox`}>
                            <p>
                                <b>{this.state.futureAlerts.lower.label}! </b>
                            {this.getAlertText(this.state.futureAlerts.lower.label, this.state.futureAlerts.lower.value)}
                            </p>
                        </div>
                     </div>)} </div>}
              </div>*/}
              <div className="OverviewStatusHedge">
                  <h4 className="OverviewSubtitle">Status Hedge</h4>
                  <canvas ref={this.chartRef}></canvas>
              </div>
              <div className="OverviewIndicator">
                  {/*<h4 className="OverviewSubtitle">Indicators</h4>*/}
                  <h4></h4>
                  <hr />
                  <div className="OverviewIndicatorArrows">
                      {this.state.arrowIndicators.map(arrowIndicator => {
                          const {label, value} = arrowIndicator;
                          return this.renderIndicatorArrow(label, value);
                      })}
                  </div>
                  <hr />
                  <div className="IndicatorTable">
                      {this.state.valueIndicators.map(indicator => {
                          const {label,value} = indicator;
                          return this.renderIndicatorValue(label, value);
                      })}
                  </div>
              </div>
          </div>
      );
    }

    // Hooks

    componentWillMount = async () => {
      const energyId = this.props.energy.id;
      const {alert,spot,table,arrows,alertMonthly,futureAlerts} = await apiClient.loadOverview(energyId);
      var barChartData = (await apiClient.getStatusHedgeByCountryV2(energyId))[0];
      if(barChartData) {
          barChartData = barChartData.barCharts;
      }
      this.setState({
        ...this.state,
        alertMonthly: alertMonthly,
        alertType: alert.label,
        alertActionValue: alert.actionValue,
        futureAlerts: futureAlerts,
        spot,
        arrowIndicators: arrows,
        valueIndicators: table,
        statusHedgeData: barChartData
      });
    };

    componentDidUpdate = async () => {

      new Chart(this.chartRef.current.getContext("2d"), {
          type: 'bar',
          data: this.state.statusHedgeData,
          options: statusHedgeChartConfig
      });
    };

    // Render Helpers

    renderIndicatorArrow(label, rotation) {
        return (
            <div key={label} className="IndicatorArrowContainer">
                <IndicatorArrow className="IndicatorArrow" rotation={rotation} />
                <p className="IndicatorArrowLabel">{label}</p>
            </div>
        );
    }

    renderIndicatorValue(label, value) {
        return (
            <div key={label} className="IndicatorTableRow">
                <span className={`circle ${label === 'Spot'? 'first-circle' : ''}`}></span>
                <p>{label}</p>
                <p className="IndicatorTableRowValue">{this.props.energy.id !== 'co2'? '€/MWh' : '€'} {value? value.toFixed(2) : ''}</p>
            </div>
        );
    }

    getAlertText(alertType, alertActionValue) {
        switch(alertType) {
            case 'Action':
                if(alertActionValue == 3) {
                    return "3 Action-barriers have been surpassed";
                } else if(alertActionValue == 2) {
                    return "2 Action-barriers have been surpassed";
                } else if(alertActionValue == 1) {
                    return "1 Action-barrier has been surpassed";
                }
                return 'price crossed threshold';
            case 'Attention':
                return 'price approaches threshold';
            default:
                return 'no action required';
        }
    }

}