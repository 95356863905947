import React from 'react';
import {Route,Redirect} from 'react-router-dom';

// React Context
import CommodityForeContext from '../CommodityForeContext.js';

export default class PrivateRoute extends React.Component {
    render() {
        const {authenticated} = this.context;
        return <Route {...this.props} render={(props => {
            if(authenticated) {
                return this.props.secureRender(props);
            } else {
                return (<Redirect to="/login" />);
            }
        })} />;
    }
}

PrivateRoute.contextType = CommodityForeContext;