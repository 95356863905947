import React from 'react';
import Chart from 'chart.js';
import 'chartjs-plugin-annotation';
import { RadioGroup, RadioButton } from 'react-radio-buttons';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import fileDownload from 'js-file-download';
import * as bp4 from '@blueprintjs/core';

// Custom Components
import IndicatorArrow from '../utils/IndicatorArrow';

// Stylesheets
import "react-tabs/style/react-tabs.css";
import '../stylesheets/EnergyTabs.css';
import '../stylesheets/StatusHedge.css';

// Helpers
import RestApiClient from '../logic/RestApiClient';
import AddHedgeDialog from './AddHedgeDialog';
import SetTargetActualLineDialog from './SetTargetActualLineDialog';
import axios from 'axios';

// React Context
import CommodityForeContext from '../CommodityForeContext.js';

// Globals
const apiClient = new RestApiClient();

function ExcelDateToJSDate(date) {
    return new Date(Math.round((date - 25569) * 86400 * 1000));
};
function thousands_separators(num) {
    var num_parts = num.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
}
function formatDate(date) {
    return date.toLocaleDateString(undefined, { month: '2-digit' }) + '/' + date.toLocaleDateString(undefined, { day: '2-digit' }) + '/' + date.toLocaleDateString(undefined, { year: 'numeric' });
};
const chartConfig = {
    tooltips: {
        titleFontSize: 22,
        bodyFontSize: 20,
        callbacks: {
            title: function (tooltipItem, data) {
                return tooltipItem.map(item => `${item.label}`).pop();
            },
            label: function (tooltipItem, data) {
                const year = tooltipItem.index;
                const dataset = data.datasets[tooltipItem.datasetIndex];
                const label = dataset.label;
                const record = data.data.find(record => record.year === year && record.label === label);
                if (record) {
                    if (dataset.backgroundColor === 'Green') {
                        var text = `Volume: ${record.volume.toFixed(2)}`;
                        if (record.price) {
                            text += `; Price: ${record.price.toFixed(2)}€`;
                        }
                        return text;
                    }
                    if (dataset.backgroundColor === 'rgb(98,181,292)') {
                        return `Spot Volume: ${(record.value * 100).toFixed(2)}%`;
                    }
                    return `Open Volume: ${(record.value * 100).toFixed(2)}%`;;
                } else {
                    return ``;
                }
            }
        }
    },
    legend: {
        position: 'bottom',
        labels: {
            filter: (legendItem, data) => {
                return legendItem.fillStyle !== 'rgb(255,255,255)';
            }
        }
    },
    scales: {
        xAxes: [{
            barPercentage: 0.5,
            id: "x-axis-0",
            gridLines: false,
            stacked: true,
        }],
        yAxes: [{
            gridLines: false,
            stacked: true,
            ticks: {
                max: 1.1,
                min: 0,
                callback: (value) => {
                    if (value <= 1.0) {
                        return `${(parseFloat(value) * 100).toFixed(0)}%`;
                    }
                    return '';
                }
            },
        }],
    },
};

class StatusHedgeComponent extends React.Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.state = {
            indicators: [],
            countries: [],
            statusHedgeByCountry: [],
            selectedCountry: 'at',
            showAddHedgeDialog: false,
            showTargetActualDialog: false
        };
        this.downloadStatusHedgeTable = this.downloadStatusHedgeTable.bind(this);
    }

    render() {
        const toggleAddHedgeDialog = (show) => {
            this.setState({
                ...this.state,
                showAddHedgeDialog: show
            });
        };
        const showAddHedgeDialog = () => toggleAddHedgeDialog(true);
        const hideAddHedgeDialog = () => toggleAddHedgeDialog(false);

        const toggleTargetActualDialog = (show) => {
            this.setState({
                ...this.state,
                showTargetActualDialog: show
            });
        };
        const showTargetActualDialog = () => toggleTargetActualDialog(true);
        const hideTargetActualDialog = () => toggleTargetActualDialog(false);

        const {admin} = this.context;
        console.log(`Admin: ${admin}`);

        return (
            <div id="StatusHedge">
                <div className="ArrowBar">
                    {this.state.indicators.map(indicator => (
                        <div key={indicator.label} className="ArrowContainer">
                            <h4>{indicator.label}</h4>
                            <IndicatorArrow className="StatusHedgeIndicatorArrow" rotation={indicator.value} />
                        </div>
                    ))}
                </div>
                <hr />
                <div className="StatusHedgeCountrySelectContainer">
                    <RadioGroup onChange={this.selectCountry} value={this.state.selectedCountry} horizontal>
                        {this.state.countries.sort().map(cc => {
                            if (cc !== 'all' && cc.length === 2) {
                                return (
                                    <RadioButton key={cc} value={cc} padding={15}><span className={`flag-icon flag-icon-${cc} flag-icon-squared flag-span`}></span></RadioButton>
                                );
                            }
                            if (cc.length > 3) {
                                const flagCc = cc.substring(0, 2);
                                console.log(flagCc);
                                return (
                                    <RadioButton key={cc} value={cc} padding={15}>
                                        <span className={`flag-icon flag-icon-${flagCc} flag-icon-squared flag-span`}></span>
                                        <span style={{ color: "black", fontSize: "6pt" }}>({cc.substring(3)})</span>
                                    </RadioButton>
                                );
                            }
                            return (
                                <RadioButton key={cc} value={cc} padding={15}><strong style={{ color: "black", "margin-right": '5px' }}>All</strong></RadioButton>
                            );
                        })}
                    </RadioGroup>
                </div>
                <canvas ref={this.chartRef}></canvas>
                <div className="StatusHedgeTables">
                    {localStorage.getItem('username') === 'pfr' && (
                        <div id="statusHedgeTableAlert">
                            <p>ℹ Preise beinhalten Gebühren und Tradingkosten</p>
                        </div>)}
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', columnGap: '5px', marginTop: '10px', marginBottom: '10px' }}>
                        <bp4.AnchorButton intent="primary" large icon="download" onClick={this.downloadStatusHedgeTable}>Download Table</bp4.AnchorButton>
                        {this.state.selectedCountry !== 'all' && <>
                            <bp4.AnchorButton intent='SUCCESS' large icon='plus' onClick={showAddHedgeDialog}>Add Hedge</bp4.AnchorButton>
                            {admin && <bp4.AnchorButton intent='danger' large icon='timeline-line-chart' onClick={showTargetActualDialog}>Set Target/Actual Line</bp4.AnchorButton>}
                        </>}
                        <AddHedgeDialog
                            isOpen={this.state.showAddHedgeDialog}
                            close={async () => {
                                hideAddHedgeDialog();
                                await this.componentWillMount();
                            }}
                            country={this.state.selectedCountry}
                            energy={this.props.energy}
                            data={this.state.statusHedgeByCountry && this.state.statusHedgeByCountry.find(({ cc }) => cc === this.state.selectedCountry)} />
                        <SetTargetActualLineDialog
                            isOpen={this.state.showTargetActualDialog}
                            close={async () => {
                                hideTargetActualDialog();
                                await this.componentWillMount();
                            }}
                            country={this.state.selectedCountry}
                            energy={this.props.energy}
                            data={this.state.statusHedgeByCountry && this.state.statusHedgeByCountry.find(({ cc }) => cc === this.state.selectedCountry)} />
                    </div>
                    {this.renderStatusHedgeTables()}
                </div>
            </div>
        );
    }

    // https://stackoverflow.com/a/34890276
    groupBy(xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    renderStatusHedgeTables() {
        const {admin} = this.context;
        console.log(`Admin: ${admin}`);
        const state = this.state.statusHedgeByCountry.find(sh => sh.cc === this.state.selectedCountry);
        if (state) {
            const tableData = state.barCharts.data || [];
            const groupedByYear = this.groupBy(tableData, 'year');
            const currentYear = new Date().getFullYear();
            return (
                <Tabs>
                    <TabList>
                        {Object.keys(groupedByYear).map(year =>
                            <Tab key={year}>{parseInt(year) + currentYear}</Tab>
                        )}
                    </TabList>
                    {Object.keys(groupedByYear).map(year => {
                        const data = groupedByYear[year];
                        return (
                            <TabPanel key={year}>
                                <table className="StatusHedgeTable">
                                    <thead>
                                        <tr>
                                            <th className="LabelCell"><b>Label/Period</b></th>
                                            <th><b>Volume</b></th>
                                            <th><b>Price</b></th>
                                            <th><b>Fee</b></th>
                                            <th><b>Date</b></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.filter(r => r.price != 0 || r.volume != 0).map(record => {
                                            return (
                                                <>
                                                    <tr key={record.label}>
                                                        <td className="LabelCell">{record.label}/{record.period}</td>
                                                        <td>{record.volume && thousands_separators(record.volume.toFixed(2))}</td>
                                                        <td>{record.price && record.price.toFixed(2)}€/MWh</td>
                                                        <td>{record.fee !== 'undefined' ? record.fee : ""}</td>
                                                        <td>{record.date ? formatDate(ExcelDateToJSDate(record.date)) : ""}</td>
                                                        <td>{(admin && record.country !== 'all') && <bp4.AnchorButton intent='danger' icon='delete' outlined onClick={async () => {
                                                            if (!window.confirm(`Please confirm if you want to delete ${record.label} (year: ${parseInt(year) + currentYear})?`)) {
                                                                return;
                                                            }
                                                            await axios.post(`${process.env.REACT_APP_HOST || 'https://commodity-fore.com'}/api/v2/hedge/delete`, {
                                                                label: record.label,
                                                                country: record.country,
                                                                energy: record.energy,
                                                                year: parseInt(year)
                                                            });
                                                            window.alert('Deleted!');
                                                            await this.componentWillMount();
                                                        }} />}</td>
                                                    </tr>
                                                    {record.label === 'Total' &&
                                                    <tr key="offeneMenge">
                                                        <td className="LabelCell">Open</td>
                                                        <td>{record.volume && thousands_separators(
                                                            record.volume - data.filter(({label}) => label !== 'Spot' && label !== 'Total').map(({volume}) => volume).reduce((a, b) => a + b)
                                                            )}</td>
                                                        <td>{record.price && record.price.toFixed(2)}€/MWh</td>
                                                        <td>{record.fee !== 'undefined' ? record.fee : ""}</td>
                                                        <td>{record.date ? formatDate(ExcelDateToJSDate(record.date)) : ""}</td>
                                                        <td></td>
                                                    </tr>}
                                                </>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </TabPanel>
                        );
                    })}
                </Tabs>
            );
        } else {
            return (<div></div>);
        }
    }

    // Hooks

    selectCountry = (cc) => {
        this.setState({
            ...this.state,
            selectedCountry: cc
        });
    };

    componentWillMount = async () => {
        const { energy } = this.props;
        const statusHedgeByCountry = await apiClient.getStatusHedgeByCountryV2(energy.id);
        const countries = statusHedgeByCountry.map(sh => sh.cc);
        const { arrows } = await apiClient.loadOverview(energy.id);
        const currentYear = new Date().getFullYear();
        statusHedgeByCountry.forEach(state => {
            state.targetActualLines.reverse().forEach(targetActual => {
                const { year, value } = targetActual;
                const data = [null, null, null];
                data[year] = value;
                state.barCharts.datasets = [{
                    year: year,
                    type: 'line',
                    label: `${year + currentYear}`,
                    data: data,
                    showLine: false,
                    backgroundColor: 'transparent',
                    borderColor: 'red',
                    pointStyle: 'line',
                    pointRadius: 120,
                    pointBorderWidth: 5,
                    hoverRadius: 120,
                    hoverBorderWidth: 5
                }].concat(state.barCharts.datasets);
            });
        });
        this.setState({
            ...this.state,
            countries,
            selectedCountry: countries[0],
            statusHedgeByCountry,
            indicators: arrows,
        });
    };

    componentDidUpdate() {
        const state = this.state.statusHedgeByCountry.find(sh => sh.cc === this.state.selectedCountry);
        const currentYear = new Date().getFullYear();
        const annotations =
            state.averagePrices.map(averagePrice => {
                const value = parseFloat(averagePrice.avg).toFixed(2);
                const valueStr = !isNaN(value) ? `Ø ${value} €/MWh` : 'Ø -';
                return {
                    type: "line",
                    mode: "vertical",
                    scaleID: "x-axis-0",
                    value: `${currentYear + parseInt(averagePrice.year)}`,
                    borderColor: "transparent",
                    label: {
                        content: valueStr,
                        enabled: true,
                        position: "top",
                        fontSize: 12,
                        yAdjust: 5,
                        xAdjust: -80
                    }
                };
            });
        const zip = rows => rows[0].map((_, c) => rows.map(row => row[c]));
        zip([state.totalSumPrices, state.sumPrices]).forEach(sums => {
            const total = sums[0];
            const hedged = sums[1];
            const valueStr = 'Σ ' + hedged.avg.trim() + '/' + total.avg.trim() + ' Gwh';
            annotations.push({
                type: "line",
                mode: "vertical",
                scaleID: "x-axis-0",
                value: `${currentYear + parseInt(total.year)}`,
                borderColor: "transparent",
                label: {
                    content: valueStr,
                    enabled: true,
                    position: "top",
                    fontSize: 12,
                    yAdjust: 5,
                    xAdjust: 80
                }
            });
        });
        const config = {
            ...chartConfig,
            annotation: {
                annotations: annotations
            }
        }
        if (this.chart) {
            this.chart.destroy();
        }
        this.chart = new Chart(this.chartRef.current.getContext("2d"), {
            type: 'bar',
            data: state.barCharts,
            options: config
        });
    }

    downloadStatusHedgeTable = async () => {
        const data = await apiClient.loadStatusHedgeTablesExcel(this.state.selectedCountry, this.props.energy.id);
        fileDownload(data, `Status Hedge ${this.state.selectedCountry}.xlsx`);
    };
}

StatusHedgeComponent.contextType = CommodityForeContext;
const StatusHedge = StatusHedgeComponent;
export default StatusHedge;