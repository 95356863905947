import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import moment from 'moment';

// Stylesheets
import './stylesheets/CommodityForeHeader.css';

// Helpers
import RestApiClient from './logic/RestApiClient';

// Globals
const apiClient = new RestApiClient();

class CommodityForeHeaderComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lastUpdate: new Date()
        };
    }

    componentDidMount = async () => {
        const {lastUpdate} = await apiClient.getLastUpdate();
        console.log(lastUpdate);
        this.setState({lastUpdate});
    };

    render() {
        const {lastUpdate} = this.state;
        return (
            <header>
                <NavLink id="LogoLink" to="/">
                    <img src="/images/logo.svg" alt="Deloitte" />
                </NavLink>
                {lastUpdate && (
                    <p id="LastUpdateLabel">
                        <strong>Last Update: </strong>
                        {moment(lastUpdate).format('DD.MM.YYYY HH:mm')}
                    </p>
                )}
                <NavLink id="IndicatorsLink" 
                      className="HeaderLink"
                      activeClassName="ActiveHeaderLink"
                      to="/indicators">Indicators</NavLink>
                <NavLink id="HedgingStrategyLink" 
                      className="HeaderLink"
                      activeClassName="ActiveHeaderLink"
                      to="/hedgingStrategy">Hedging Strategy</NavLink>
                <NavLink id="StatusHedgeLink" 
                      className="HeaderLink"
                      activeClassName="ActiveHeaderLink"
                      to="/statusHedge">Status Hedge</NavLink>
            </header>
        );
    }
}

const CommodityForeHeader = withRouter(CommodityForeHeaderComponent);
export default CommodityForeHeader;