import React from 'react';
import * as bp4 from '@blueprintjs/core';
import { Formik } from 'formik';
import axios from 'axios';

export default function SetTargetActualLineDialog({ isOpen = false, close = () => { }, country, energy, data }) {
    const cc = country.length > 2 ? country.substring(0, 2) : country;
    const currentYear = (new Date()).getFullYear();
    const { barCharts: { labels } } = data || { barCharts: { labels: [] } };
    const { targetActualLines = [] } = data || {};
    const initialValues = {
        year: 0,
        targetActualLines: targetActualLines,
        procurmentVolume: 0
    };
    return <bp4.Dialog isOpen={isOpen} onClose={close} title={<span>Set Target/Actual Line for <span className={`flag-icon flag-icon-${cc} flag-icon-squared flag-span`}></span></span>} icon="plus" isCloseButtonShown={true}>
        <div className={bp4.Classes.DIALOG_BODY}>
            <Formik initialValues={initialValues} onSubmit={async (values, { setErrors, resetForm }) => {
                const {targetActualLines} = values;
                await axios.put(`${process.env.REACT_APP_HOST || 'https://commodity-fore.com'}/api/v2/targetActualLine`, targetActualLines);
                resetForm();
                close();
            }}>
                {({
                    values,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    resetForm
                }) => {
                    return <form onSubmit={handleSubmit}>
                        <p>Here you can determine the hedging target amount for the year {currentYear + values.year}.</p>
                        <bp4.FormGroup
                            label="Year"
                            helperText="Select the year to define the Target/Actual line for.">
                            <bp4.Slider
                                fill
                                required
                                min={0}
                                max={labels.length - 1}
                                stepSize={1}
                                labelRenderer={idx => labels[idx]}
                                disabled={isSubmitting}
                                value={values.year}
                                onChange={year => setFieldValue('year', year)} />
                        </bp4.FormGroup>
                        <bp4.FormGroup
                            label="Target/Actual Value"
                            labelInfo={`(${currentYear + values.year})`}
                            helperText="Sets the value of the Target/Actual line on the status hedges Y axis.">
                            <bp4.NumericInput
                                name="avg"
                                fill
                                required
                                min={-1}
                                max={1000000}
                                stepSize={0.1}
                                minorStepSize={0.01}
                                onBlur={handleBlur}
                                disabled={isSubmitting}
                                value={values.targetActualLines.find(({ year }) => year === values.year)?.value?.toFixed(2)}
                                onValueChange={newValue => {
                                    const update = values.targetActualLines;
                                    update.find(({ year }) => year === values.year).value = newValue;
                                    setFieldValue('targetActualLines', update);
                                }} />
                        </bp4.FormGroup>
                        <bp4.FormGroup
                            label="Procurment Volume"
                            helperText={`The total procurment volume for the year ${currentYear + values.year} to be reached.`}>
                                <bp4.NumericInput 
                                    name="procurmentVolume"
                                    fill
                                    required
                                    min={-1}
                                    max={1000000}
                                    stepSize={0.1}
                                    minorStepSize={0.01}
                                    onBlur={handleBlur}
                                    disabled={isSubmitting}
                                    value={values.procurmentVolume}
                                    onValueChange={newValue => setFieldValue('procurmentVolume', newValue)} />
                            </bp4.FormGroup>
                        <div className={bp4.Classes.DIALOG_FOOTER_ACTIONS}>
                            <bp4.Button intent='primary' icon='saved' type="submit">Save</bp4.Button>
                            <bp4.Button intent='danger' icon='cross-circle' onClick={() => {
                                resetForm();
                                close();
                            }}>Cancel</bp4.Button>
                        </div>
                    </form>;
                }}
            </Formik>
        </div>
    </bp4.Dialog>;
}