import React from 'react';
import Chart from 'chart.js';
import 'chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.js';
import 'chartjs-plugin-annotation';
import fileDownload from 'js-file-download';
import { RadioGroup, RadioButton } from 'react-radio-buttons';

// Stylesheets
import '../stylesheets/HedgingStrategy.css';

// Helpers
import RestApiClient from '../logic/RestApiClient';

// Globals
const apiClient = new RestApiClient();

function partition(array, n) {
    return array.length ? [array.splice(0, n)].concat(partition(array, n)) : [];
}    

export default class HedgingStrategy extends React.Component {

    constructor(props) {
        super(props);
        this.chartRef = React.createRef();
        this.state = {
            selectedCountry: 'all',
            hedgingStrategies: {
                all: {
                    boxes: [],
                    lines: [],
                    dots: [],
                    spot: []
                }
            }
        };
    }

    render() {
        const state = JSON.parse(JSON.stringify(this.state.hedgingStrategies[this.state.selectedCountry]));
        const countries = Object.keys(this.state.hedgingStrategies);
        return (
            <div id="HedgingStrategy">

                <RadioGroup onChange={ this.selectCountry } value={this.state.selectedCountry} horizontal>
                    {countries.sort().map(cc => {
                        if(cc !== 'all') {
                            return (
                                <RadioButton key={cc} value={cc} padding={15}><span className={`flag-icon flag-icon-${cc} flag-icon-squared flag-span`}></span></RadioButton>
                            );
                        }
                        return (
                            <RadioButton key={cc} value={cc} padding={15}><strong style={{color: "black", "margin-right": '5px'}}>All</strong></RadioButton>
                        );
                    })}
                </RadioGroup>

                <table className="HedgingPriceTable">
                    {state && partition(state.spot.sort((a,b) => a.name > b.name), 3).map(rowItems => {
                        const cols = [];
                        rowItems.forEach(price => {
                            const {name,value} = price;
                            cols.push(<td style={{textAlign: "right", textDecoration: "underline"}}>{name}:</td>);
                            cols.push(<td style={{textAlign: "center"}}>{value} {this.props.energy.id !== 'co2'? '€/MWh' : '€/co2 cert'}</td>)
                        });
                        return <tr>{cols}</tr>;
                    })}
                </table>

                <canvas ref={this.chartRef}></canvas>
                <div>
                    <h3>Box-Plot Chart</h3>
                    <p>
                        This chart shows the development of the price range and volatility development for the +1-month and the +1-year futures as well as the predefined stop-loss and strike-point levels
                        <br />
                        <br />
                        The box-plots from left to right show the historical price development and the volatility of green: +1-month and blue: +1-year over a period of left: 12 months – mid: 6 months – right: 1 month.
                        <br />
                        <br />
                        The dots in the middle show the historical and current price of the selected commodity. 
                        <br />
                        <br />
                        The lines show the predefined hedging levels, based on standard deviations. The orange lines show the strike-point levels and the gray the stop-loss levels.
                        <br />
                        <br />
                        Note: Data can be individually selected or deselected on this chart
                    </p>
                    <button onClick={this.downloadPdf}>Download PDF</button>
                </div>
            </div>
        );
    }

    // Hooks

    selectCountry = (cc) => {
        this.setState({
            ...this.state,
            selectedCountry: cc
        });
    };

    componentWillMount = async () => {
      const {energy} = this.props;
      const hedgingStrategies = await apiClient.loadHedgingStrategiesV2(energy.id);
      this.setState({
        ...this.state,
        hedgingStrategies: hedgingStrategies,
        selectedCountry: Object.keys(hedgingStrategies)[0]
      });
    };

    componentDidUpdate() {
        const state = JSON.parse(JSON.stringify(this.state.hedgingStrategies[this.state.selectedCountry]));
        state.dots.forEach(dot => {
            state.boxes.push({
                type: 'line',
                label: dot.label,
                showLine: false,
                backgroundColor: dot.color,
                borderColor: dot.color,
                borderWidth: 5,
                data: [parseFloat(dot.value)]
            });
        });
        var range = this.getBoxPlotRange();
        var {max,min} = range;
        var biggestLine = state.lines.filter(l => l.value > max).sort().map(l => l.value).pop();
        if(biggestLine) {
            max = biggestLine + 5;
        }
        var lowestLine = state.lines.filter(l => l.value < min).sort().map(l => l.value).pop();
        if(lowestLine) {
            min = lowestLine - 5;
        }
        if(this.oldChart) {
            this.oldChart.destroy();
        }
        this.oldChart = new Chart(this.chartRef.current.getContext("2d"), {
            type: 'boxplot',
            data: {
                labels: [],
                datasets: JSON.parse(JSON.stringify(state.boxes))
            },
            options: {
              legend: {
                  position: 'bottom'
              },
              tooltips: {
                  callbacks: {
                      title: function(tooltipItem, data) {
                          return data.datasets[tooltipItem[0].datasetIndex].label;
                      },
                      label: (tooltipItem, data) => {
                          try {
                            const {value} = tooltipItem;
                            return `${parseFloat(value).toFixed(2)} EUR`;
                          } catch(e) {
                              console.log(e);
                              return '-';
                          }
                      }
                  }
              },
              scales: {
                  xAxes: [
                      {
                          id: 'x-axis-0',
                          gridLines: false
                      }
                  ],
                  yAxet: [
                      {
                          id: 'y-axis-0',
                          gridLines: false,
                          scaleLabel: {
                              display: true,
                              fontSize: 16,
                              labelString: this.buildYAxisLabel()
                          },
                          ticks: {
                              min,max
                          }
                      }
                  ]
              },
              annotation: {
                  annotations: JSON.parse(JSON.stringify(state.lines))
              }
            },
            annotation: {
                drawTime: 'afterDatasetsDraw',
                dblClickSpeed: 350
            }
        });
    }

    getBoxPlotRange = () => {
      switch(this.props.energy.id) {
          case 'co2':
              return {
                  min: 10,
                  max: 40
              };
          case 'power':
              return {
                  min: 5,
                  max: 65
              };
          case 'gas': 
              return {
                  min: 0,
                  max: 25
              };
          default: 
              return {
                  min: 0,
                  max: 100
              };
      }
  }

  buildYAxisLabel = () => {
    const {id} = this.props.energy;
    switch(id) {
        case 'co2':
            return `EUR/${id} Certificate`;
        default:
            return `EUR/MWh`;
    }
  };

  downloadPdf = async () => {
      const data = await apiClient.downloadHedgingStrategyPdf();
      fileDownload(data, 'hedgingStrategy.pdf');
  };
}