import React from 'react';
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';

// Stylesheets
import './stylesheets/CommodityForeFooter.css';

// React Context
import CommodityForeContext from './CommodityForeContext.js';

class CommodityForeFooterComponent extends React.Component {

    render() {
        const {authenticated,admin} = this.context;
        return (
            <footer>
                <a id="ImprintLink" 
                   target="_blank" 
                   rel="noopener noreferrer" 
                   href="https://www2.deloitte.com/at/de/footerlinks1/legal.html?icid=bottom_legal">
                    Imprint
                </a>
                <a id="TermsAndConditionsLinks" 
                   target="_blank" 
                   rel="noopener noreferrer" 
                   href="/documents/AGBs.pdf">
                    Terms &amp; Conditions
                </a>
                {admin && <Link id="AdminLink" to="/admin">Admin</Link>}
                {authenticated && <button id="LogoutButton" onClick={this.props.onLogout}>Logout</button>}
            </footer>
        );
    }
}

CommodityForeFooterComponent.contextType = CommodityForeContext;

const CommodityForeFooter = withRouter(CommodityForeFooterComponent);
export default CommodityForeFooter;