import React from 'react';

export default class UploadComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null
        };
    }

    render() {
        return (
            <div>
                <strong>{this.props.label}:</strong><br />
                <input type="file" name="spreadsheet" onChange={this.selectFile} />
                <button type="button" onClick={this.upload}>Upload</button> 
            </div>
        );
    }

    selectFile = (event) => {
        this.setState({
            selectedFile: event.target.files[0],
            loaded: 0,
        });
    };

    upload = () => {
        const {selectedFile} = this.state;
        if(selectedFile) {
            this.props.upload(selectedFile);
        }
    };
}