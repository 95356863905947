import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

// Stylesheets
import "react-tabs/style/react-tabs.css";
import '../stylesheets/EnergyTabs.css';

export default class EnergyTabs extends React.Component {

    render() {
        const { location, energies, PanelComponent } = this.props;

        // If the Query Parameter "energyId" is set, use it to pre-select the tab.
        var selectedEnergy = 0;
        if(location && location.search) {
            const {energyId} = this.parseQuery(location.search);
            if(energyId) {
                var energy = energies.find(e => e.id === energyId);
                if(energy) {
                    selectedEnergy = energies.indexOf(energy);
                }
            }
        }

        return (
            <div id="Content">
                <Tabs className="EnergyTabs" defaultIndex={selectedEnergy}>
                    <TabList className="EnergyTabList">
                        {energies.map(energy => (
                            <Tab key={energy.id} className={["react-tabs__tab", "EnergyTab"]} selectedClassName="SelectedEnergyTab">{energy.name}</Tab>
                        ))}
                    </TabList>
                    {energies.map(energy => (
                        <TabPanel key={energy.id} className="EnergyPanel">
                            <PanelComponent energy={energy} />
                        </TabPanel>
                    ))}
                </Tabs>
            </div>
        );
    }

    parseQuery(queryString) {
        var query = {};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        for (var i = 0; i < pairs.length; i++) {
            var pair = pairs[i].split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
        return query;
    }

}