import axios from 'axios';

export default class RestApiClient {

    constructor() {
        this.host = process.env.REACT_APP_HOST || 'https://commodity-fore.com';
    }

    setToken(token) {
        axios.defaults.headers.common['Authorization'] = token;
    }

    async login(username, password) {
        return (await axios.post(`${this.host}/api/v1/login`, {username,password})).data;
    }

    async listEnergies() {
        return (await axios.get(`${this.host}/api/v1/energies`)).data;
    }

    async listUsers() {
        return (await axios.get(`${this.host}/api/v1/users`)).data;
    }

    async getLastUpdate() {
        return (await axios.get(`${this.host}/api/v1/lastUpdate`)).data;
    }

    async getSpot(energyId) {
        return (await axios.get(`${this.host}/api/v1/energies/${energyId}/spot`)).data.spot;
    }

    async loadOverview(energyId) {
        return (await axios.get(`${this.host}/api/v1/energies/${energyId}/overview`)).data;
    } 

    async loadIndicatorsGraph(energyId, timespan) {
        return (await axios.get(`${this.host}/chartjs-api/v1/energies/${energyId}/indicators/${timespan}/graph`)).data;
    }

    async loadHedgingStrategies(energyId) {
        return (await axios.get(`${this.host}/chartjs-api/v1/energies/${energyId}/hedgingStrategies`)).data;
    }

    async loadHedgingStrategiesV2(energyId) {
        return (await axios.get(`${this.host}/chartjs-api/v1/energies/${energyId}/countries/hedgingStrategies`)).data;
    }

    async getStatusHedgeByCountry(energyId) {
        return (await axios.get(`${this.host}/chartjs-api/v1/energies/${energyId}/statusHedge`)).data;
    }

    async getStatusHedgeByCountryV2(energyId) {
        return (await axios.get(`${this.host}/chartjs-api/v2/energies/${energyId}/statusHedge`)).data;
    }

    async getStatusHedgeData(energyId) {
        return (await axios.get(`${this.host}/chartjs-api/v1/energies/${energyId}/statusHedge/barChart`)).data;
    }

    async getStatusHedgeAveragePrices(energyId) {
        return (await axios.get(`${this.host}/api/v1/energies/${energyId}/statusHedge/averagePrices`)).data;
    }

    async getStatusHedgeTargetActual(energyId) {
        return (await axios.get(`${this.host}/chartjs-api/v1/energies/${energyId}/statusHedge/targetActual`)).data;
    }

    async uploadSpreadsheet(user, data) {
        await axios.put(`${this.host}/api/v1/spreadsheet/${user}`, data);
    }

    async uploadHedgingStrategyPdf(user, data) {
        await axios.put(`${this.host}/api/v1/hedgingStrategy/pdf/${user}`, data);
    }

    async downloadHedgingStrategyPdf() {
        return (await axios.get(`${this.host}/api/v1/hedgingStrategy.pdf`,{
            responseType: 'arraybuffer'
        })).data;
    }

    async loadStatusHedgeTablesExcel(cc, energyId) {
        return (await axios.get(`${this.host}/api/v1/countries/${cc}/energies/${energyId}/statusHedgeNew.xlsx`,{
            headers: {
                'content-disposition': `attachment; filename="StatusHedge${cc}.xlsx"`
            },
            responseType: 'arraybuffer'
        })).data;
    }

    async addUser(username, password) {
        (await axios.post(`${this.host}/api/v1/users`, {username,password}));
    }

    async updateUser(user) {
        (await axios.patch(`${this.host}/api/v1/users`, user));
    }

    async deleteUser(user) {
        (await axios.delete(`${this.host}/api/v1/users/${user}`));
    }
}