import React from 'react';
import Chart from 'chart.js';
import { Link } from 'react-router-dom';

// Custom Components
import IndicatorArrow from '../utils/IndicatorArrow';

// Stylesheets
import '../stylesheets/CommodityIndicators.css';

// Helpers
import RestApiClient from '../logic/RestApiClient';

// Globals
const apiClient = new RestApiClient();

const today = function () {
  const date = new Date();
  return date.toLocaleDateString(undefined, { month: '2-digit' }) + '/' + date.toLocaleDateString(undefined, { day: '2-digit' }) + '/' + date.toLocaleDateString(undefined, { year: 'numeric' });
}

const oneYearIndicatorBarChartMap = {
  'co2': -10000,
  'power': -2000,
  'gas': -9000
};

function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

export default class CommodityIndicatorChart extends React.Component {

  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      chartData: {}
    }
  }

  render() {
    var energy, indicator, arrowIndicator, divId = 'no-content';
    if (!this.props.energy) {
      const data = parseQuery(this.props.location.search);
      energy = { id: data.energyId };
      indicator = { id: data.indicatorId, label: data.indicatorLabel, displayUnit: data.indicatorDisplayUnit };
      arrowIndicator = { value: data.arrowIndicator };
      divId = 'Content'
    } else {
      energy = this.props.energy;
      indicator = this.props.indicator;
      arrowIndicator = this.props.arrowIndicator;
    }
    return (
      <div id={divId} className="IndicatorPanel">
        <div className="IndicatorPanelHeader">
          <IndicatorArrow color="gray" rotation={arrowIndicator ? arrowIndicator.value : 0} />
          <Link to={`/indicators/detail?energyId=${energy.id}&indicatorId=${indicator.id}&indicatorLabel=${indicator.label}&indicatorDisplayUnit=${indicator.displayUnit}&arrowIndicator=${arrowIndicator ? arrowIndicator.value : 0}`}>
            {`< ${indicator.label}`}
          </Link>
          {divId !== 'Content' &&
            <Link to={`/indicators/info?energyId=${energy.id}&indicatorId=${indicator.id}&indicatorLabel=${indicator.label}&indicatorDisplayUnit=${indicator.displayUnit}&arrowIndicator=${arrowIndicator ? arrowIndicator.value : 0}`}>
              ℹ
            </Link>}
          {divId === 'Content' &&
            <Link to={`/indicators?energyId=${energy.id}`}>
              ⇲
            </Link>}
        </div>
        <canvas ref={this.chartRef}></canvas>
      </div>
    );
  }

  // Hooks

  componentWillMount = async () => {
    var energy, indicator;
    if (!this.props.energy) {
      const data = parseQuery(this.props.location.search);
      energy = { id: data.energyId };
      indicator = { id: data.indicatorId };
    } else {
      energy = this.props.energy;
      indicator = this.props.indicator;
    }
    const chartData = await apiClient.loadIndicatorsGraph(energy.id, indicator.id);
    this.setState({
      ...this.state,
      chartData
    });
  };

  componentDidUpdate() {
    const data = this.state.chartData.data;
    new Chart(this.chartRef.current.getContext("2d"), {
      type: 'bar',
      data,
      options: this.createChartConfig(data)
    });
  }

  // Helpers

  getTodayLastYear() {
    var date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    return date;
  }

  createChartConfig(data) {
    var indicator;
    var energy;
    if (!this.props.energy) {
      const data = parseQuery(this.props.location.search);
      indicator = { id: data.indicatorId, label: data.indicatorLabel, displayUnit: data.indicatorDisplayUnit };
      energy = { id: data.energyId };
    } else {
      indicator = this.props.indicator;
      energy = this.props.energy;
    }
    const baseConfig = {
      tooltips: {
        callbacks: {
          title: (tooltipItem, data) => tooltipItem.map(item => `${item.label} => ${parseFloat(item.value).toFixed(2).toString()}`).pop()
        }
      },
      legend: {
        position: 'bottom'
      },
      scales: {
        xAxes: [{
          id: 'x-axis-0',
          gridLines: false,
          type: 'time',
          distribution: 'linear',
          time: {
            unit: indicator.displayUnit,
            displayFormats: {
              year: 'MMM/YY',
            },
            //min: energy.id === 'power'? this.getTodayLastYear() : null
          }
        }],
        yAxes: [
          {
            gridLines: false,
            id: 'line',
            type: 'linear',
            position: 'left',
            scaleLabel: {
              display: true,
              labelString: 'EUR'
            }
          },
        ]
      },
      annotation: {
        annotations: [
          {
            type: "line",
            mode: "vertical",
            scaleID: "x-axis-0",
            value: today(),
            borderColor: "black",
            borderDash: [2, 2],
            label: {
              content: "TODAY",
              enabled: true,
              position: "top"
            }
          },
        ]
      }
    };
    if (indicator.id === '2-months') {
      baseConfig.scales.yAxes[0].scaleLabel = {
        display: true,
        labelString: 'Synthetic Indicator'
      };
      baseConfig.scales.yAxes[0].ticks = {
        callback: (value, index, values) => {
          return `${(value * 100).toFixed(0)}%`;
        }
      };
    }
    if (indicator.id === '4-months') {
      baseConfig.scales.yAxes[0].scaleLabel = {
        display: true,
        labelString: 'EUR/MWh'
      };
      baseConfig.scales.yAxes[0].ticks = {
        labelOffset: -10
      };
    }
    if (indicator.id === '1-to-3-years') {
      baseConfig.scales.yAxes[0].scaleLabel = {
        display: true,
        labelString: 'EUR/MWh'
      };
    }
    if (indicator.id === '1-year') {
      const barPoints = data? data.datasets.filter(({type}) => type === 'bar').map(({data}) => data).flat().filter(v => v !== null) : [];
      const linePoints = data? data.datasets.filter(({type}) => type === 'line').map(({data}) => data).flat().filter(v => v !== null) : [];
      baseConfig.scales.yAxes = [
        {
          gridLines: false,
          id: 'line',
          type: 'linear',
          position: 'left',
          ticks: {
            min: Math.min.apply(Math, barPoints), //energy.id === 'gas' ? -29.5 : (energy.id === 'co2' ? -35 : -25),
            max: Math.max.apply(Math, linePoints) //energy.id === 'power' ? 180 : (energy.id === 'co2' ? 90 : 80)
          },
          scaleLabel: {
            display: true,
            labelString: 'EUR/MWh'
          }
        },
        {
          gridLines: false,
          id: 'bar',
          type: 'linear',
          position: 'right',
          ticks: {
            //min: oneYearIndicatorBarChartMap[energy.id],
            //suggestedMin: oneYearIndicatorBarChartMap[energy.id],
            min: Math.min.apply(Math, linePoints),
            suggestedMin: Math.min.apply(Math, linePoints)
          },
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Volume'
          }
        }
      ];
    }
    return baseConfig;
  }
}