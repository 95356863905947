import React from 'react';

// Stylesheets
import '../stylesheets/CommodityOverviews.css';

// Custom Components
import CommodityOverview from './CommodityOverview';

export default class CommodityOverviews extends React.Component {

    render() {
        return (
            <div id="Content" className="CommodityOverviews">
                {this.props.energies.map(energy => (
                    <CommodityOverview key={energy.id} energy={energy} alertType="Action" />
                ))}
            </div>
        );
    }
}