import React from 'react';

// Custom Components
import UploadComponent from './UploadComponent';

// Stylesheets
import '../stylesheets/admin/AdminInterface.css';

// Helpers
import RestApiClient from '../logic/RestApiClient';

const apiClient = new RestApiClient();

export default class AdminInterface extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            users: []
        };
        this.setUsername = this.setUsername.bind(this);
        this.setPassword = this.setPassword.bind(this)
    }

    render() {
        return (
            <div className="AdminInterface">
                <div id="AdminInterfaceContainer">
                    <select onChange={this.selectUser}>
                        {this.state.users.map(user => (<option key={user} value={user}>{user}</option>))}
                    </select>
                    <UploadComponent label="Spreadsheet" upload={this.uploadSpreadsheet} />
                    <UploadComponent label="Hedging Strategy PDF" upload={this.uploadPdf} />
                    <h2>User Management</h2>
                    <fieldset>
                        <label for="new-user-username">New User:</label>
                        <input id="new-user-username" type="text" maxLength="3" onChange={this.setUsername} />
                        <label for="new-user-password">Password:</label>
                        <input id="new-user-password" type="password" onChange={this.setPassword} />
                        <button onClick={this.addUser}>Add User</button>
                    </fieldset>
                    <table className="UserManagementTable">
                        <thead>
                            <th>Username</th>
                            <th>Actions</th>
                        </thead>
                        <tbody>
                            {this.state.users.map(user => (
                                <tr key={user}>
                                    <td>{user}</td>
                                    <td>
                                        {user !== 'adm' && (<button onClick={() => this.renameUser(user)}>Rename</button>)}
                                        <button onClick={() => this.changePassword(user)}>Change Password</button>
                                        {user !== 'adm' && (<button onClick={() => this.remove(user)}>Remove</button>)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    selectUser = (event) => {
        this.setState({
            selectedUser: event.target.value
        });
    };

    uploadSpreadsheet = async (selectedFile) => {
        if(selectedFile) {
            const data = new FormData();
            data.append('spreadsheet', selectedFile);
            await apiClient.uploadSpreadsheet('default', data);
            alert('Done.');
        }
    };

    uploadPdf = async (selectedFile) => {
        if(selectedFile) {
            const data = new FormData();
            data.append('pdf', selectedFile);
            await apiClient.uploadHedgingStrategyPdf(this.state.selectedUser, data);
            alert('Done.');
        }
    };

    componentWillMount = async () => {
        const users = (await apiClient.listUsers());
        this.setState({
            ...this.state,
            users: users,
            selectedUser: users[0]
        });
    };

    addUser = async () => {
        const {username,password} = this.state;
        if(username === '' || password === '' || !username || !password) {
            alert("Either password or username missing!");
            return;
        }
        if(this.state.users === [] || this.state.users.indexOf(username) != -1) {
            alert("Username already in use!");
            return;
        }
        if(password.length < 5) {
            alert("Password must be 5 characters or longer!");
            return;
        }
        await apiClient.addUser(username, password);
        const users = (await apiClient.listUsers());
        this.setState({
            ...this.state,
            users: users,
            selectedUser: users[0]
        }); 
    };

    renameUser = async (username) => {
        const newUsername = prompt('Type in new Username:');
        if(this.state.users.indexOf(newUsername) != -1) {
            alert(`Username ${newUsername} already in use!`);
            return;
        }
        await apiClient.updateUser({username,newUsername});
        const users = (await apiClient.listUsers());
        this.setState({
            ...this.state,
            users: users,
            selectedUser: users[0]
        }); 
    };

    changePassword = async (username) => {
        const newPassword = prompt('Type in new Password:');
        await apiClient.updateUser({username,newPassword});
        const users = (await apiClient.listUsers());
        this.setState({
            ...this.state,
            users: users,
            selectedUser: users[0]
        }); 
    };

    remove = async (username) => {
        await apiClient.deleteUser(username);
        const users = (await apiClient.listUsers());
        this.setState({
            ...this.state,
            users: users,
            selectedUser: users[0]
        }); 
    };

    setUsername(e) {
        const username = e.target.value;
        this.setState({
            ...this.state,
            username
        });
    }

    setPassword(e) {
        const password = e.target.value;
        this.setState({
            ...this.state,
            password
        });
    }
}