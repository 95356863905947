import React from 'react';
import { Link } from 'react-router-dom';

// Custom Components
import IndicatorArrow from '../utils/IndicatorArrow';

const infoTexts = {
    '2-months': {
        heading: 'Synthetic indicator',
        paragraphs: [
            'This is a Deloitte calculated indicator consisting of highly correlating commodity prices.',
            'The green line shows the selected commodity, while the orange line shows the calculated indicator. The light blue lines show the development of highly correlating commodity prices.',
            'The indicator is recalculated daily and achieves a high tracking record (minimum: 90%). Based on the relative position between the indicator and the commodity price and trend indications can be Estimated, which is reflected in the calculation of the arrows.',
            'Note: Data can be individually selected or deselected on this chart.'
        ]
    },
    '4-months': {
        heading: 'Average term indicator',
        paragraphs: [
            'This indicator tracks the average development of the most actively traded monthly futures.',
            'The gray line shows the monthly-average spot price. The green dots show the monthly-average price of the 1 to 4/6 month-futures. The darker the green dot the smaller the period until delivery. The dots allow for an outlook in future spot price developments and might signal market trend and price inversions.',
            'Note: Data can be individually selected or deselected on this chart'
        ]
    },
    '1-year': {
        heading: 'Under 1 Year Indicator',
        paragraphs: [
            'This indicator shows the historical development of the +1 year future as well as the traded volume of the future',
            'The blue line shows the development of the +1 year future (values on left y-axis). The traded volume is shown in a bar-chart format in the background and the value are on the right y-axis. The light blue line shows the development of the Clean Dark Spread, which reflects the cost of generating electricity from coal after considering the cost of fuel and allowances',
            'Note: Data can be individually selected or deselected on this chart'
        ]
    },
    '1-to-3-years': {
        heading: 'Long-term chart',
        paragraphs: [
            'This chart shows a combination of historical data as well as provides an outlook on the long-term development of annual futures',
            'The gray line shows the average spot price development of past quarters, while the orange line links the most recent future prices. The green dots show the development of 3 annual futures: +1 year, +2 years & +3 years over the last 12 months. The darker the green dot the closer is the value to the current market price. This offers a good overview of the year-future-prices over the past 12 months. The orange line connects the current prices of the annual futures and thus shows the Forward-Curve of the future prices.',
            'Note: Data can be individually selected or deselected on this chart'
        ]
    }
};

function parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }

export default class CommodityIndicatorInfo extends React.Component {

    render() {
        var energy, indicator, arrowIndicator, divId = 'no-content';
        if(!this.props.energy) {
            const data = parseQuery(this.props.location.search);
            energy = {id: data.energyId};
            indicator = {id: data.indicatorId, label: data.indicatorLabel,displayUnit: data.indicatorDisplayUnit};
            arrowIndicator = {value: data.arrowIndicator};
            divId = 'Content'
        } else {
            energy = this.props.energy;
            indicator = this.props.indicator;
            arrowIndicator = this.props.arrowIndicator;
        }
        return <div id={divId} className="IndicatorInfoPanel">
                    <div className="IndicatorInfoPanelHeader">
                        <IndicatorArrow color="gray" rotation={arrowIndicator? arrowIndicator.value : 0} />
                        <Link to={`/indicators/detail?energyId=${energy.id}&indicatorId=${indicator.id}&indicatorLabel=${indicator.label}&indicatorDisplayUnit=${indicator.displayUnit}&arrowIndicator=${arrowIndicator? arrowIndicator.value : 0}`}>
                        {`< ${indicator.label}`}
                        </Link>
                        <Link to={`/indicators?energyId=${energy.id}`}>
                            ⇲
                        </Link>
                    </div>
                    <div className="IndicatorInfoPanelBody">
                        <hr />
                        <br />
                        <h2>{infoTexts[indicator.id]['heading']}</h2>
                        <br />
                        {infoTexts[indicator.id]['paragraphs'].map(text => <p key={text}>{text} <br /><br /></p>)}
                    </div>
                </div>;
    }

}