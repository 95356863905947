import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

// Stylesheets
import './stylesheets/CommodityForeApp.css';

// Custom Components
import PrivateRoute from './utils/PrivateRoute';
import LoginMask from './login/LoginMask';
import CommodityForeHeader from './CommodityForeHeader';
import CommodityForeFooter from './CommodityFooter';
import CommodityOverviews from './overview/CommodityOverviews';
import EnergyTabs from './utils/EnergyTabs';
import CommodityIndicators from './indicators/CommodityIndicators';
import CommodityIndicatorChart from './indicators/CommodityIndicatorChart';
import CommodityIndicatorInfo from './indicators/CommodityIndicatorInfo';
import HedgingStrategy from './hedging-strategy/HedgingStrategy';
import StatusHedge from './status-hedge/StatusHedge';
import AdminInterface from './admin/AdminInterface';

// React Context
import CommodityForeContext from './CommodityForeContext.js';

// Helpers
import RestApiClient from './logic/RestApiClient';
import {decode} from 'jsonwebtoken';


// Globals
const apiClient = new RestApiClient();

export default class CommodityForeApp extends React.Component {

    constructor(props) {
        super(props);
        const username = localStorage.getItem('username');
        const access_token = localStorage.getItem('access_token');
        const energies = localStorage.getItem('energies');
        if(username && access_token && energies) {
            const {exp,admin} = decode(access_token);
            if(Math.floor(Date.now() / 1000) < exp) {
                this.state = {
                    user: {
                        username,
                        authenticated: true,
                        admin
                    },
                    energies: JSON.parse(energies)
                };
                apiClient.setToken(access_token);
            } else {
                this.state = {
                    user: {
                        username: "",
                        authenticated: false,
                        admin: false
                    },
                    energies: []
                };
            }
        } else { 
            this.state = {
                user: {
                    username: "",
                    authenticated: false,
                    admin: false
                },
                energies: []
            };
        }
        this.onLogin = this.onLogin.bind(this);
        this.logout = this.logout.bind(this);
    }

    render() {
        return (
            <Router>
                <div className="App">
                    <CommodityForeContext.Provider value={this.state.user}>
                        <CommodityForeHeader />
                        <main>
                            <h1 id="ContentTitle">Commodity FORE</h1>
                            <Route exact path="/login" render={props => (
                                <LoginMask {...props} onLogin={this.onLogin} />
                            )} />
                            <PrivateRoute exact path="/" secureRender={props => (
                                <CommodityOverviews {...props} energies={this.state.energies} />
                            )} />
                            <PrivateRoute exact path="/indicators" secureRender={props => (
                                <EnergyTabs {...props} energies={this.state.energies} PanelComponent={CommodityIndicators} />
                            )} />
                            <PrivateRoute exact path="/indicators/detail" secureRender={props => (
                                <CommodityIndicatorChart {...props} />
                            )} />
                            <PrivateRoute exact path="/indicators/info" secureRender={props => (
                                <CommodityIndicatorInfo {...props} />
                            )} />
                            <PrivateRoute exact path="/hedgingStrategy" secureRender={props => (
                                <EnergyTabs {...props} energies={this.state.energies} PanelComponent={HedgingStrategy} />
                            )} />
                            <PrivateRoute exact path="/statusHedge" secureRender={props => (
                                <EnergyTabs {...props} energies={this.state.energies} PanelComponent={StatusHedge} />
                            )} />
                            <PrivateRoute exact path="/admin" secureRender={props => (
                                <AdminInterface {...props} />
                            )} />
                        </main>
                        <CommodityForeFooter onLogout={this.logout} />
                    </CommodityForeContext.Provider>
                </div>
            </Router>
        );
    }

    onLogin = async (username, access_token) => {
        localStorage.setItem('username', username);
        localStorage.setItem('access_token', access_token);
        const {admin} = decode(access_token);
        const energies = await apiClient.listEnergies();
        localStorage.setItem('energies', JSON.stringify(energies));
        this.setState({
            ...this.state,
            user: {
                username,
                authenticated: true,
                admin
            },
            energies
        });
    }

    logout() {
        localStorage.removeItem('username');
        localStorage.removeItem('access_token');
        this.setState({
            ...this.state,
            user: {
                username: "",
                authenticated: false,
                admin: false
            }
        });
    }

}